<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">创建或邀请用户</div>
    <div class="user-edit-content">
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          基本信息
        </p>
        <Form ref="form" :model="form" :label-width="60" style="margin-right: 10px;">
          <FormItem prop="mobile" label="手机号">
            <Input ref="mobile" type="text" maxlength="11" v-model="form.mobile" placeholder="请输入手机号" @on-change="mobileChanged"></Input>
          </FormItem>
          <FormItem prop="name" label="姓名">
            <Input ref="name" type="text" maxlength="20" v-model="form.name" :readonly="!isAdd" placeholder="请输入姓名"></Input>
          </FormItem>
          <FormItem v-if="isAdd" prop="password" label="密码">
            <Input ref="password" type="text" maxlength="20" v-model="form.pswd" placeholder=""></Input>
          </FormItem>
          <!-- <FormItem v-else :label-width="0">
            用户已存在，设置用户权限并邀请加入项目
          </FormItem> -->
          <!-- <FormItem prop="customerId" label="所在组织">
            <Input type="text" maxlength="20" v-model="custName" readonly placeholder="请输入名称"></Input>
          </FormItem>
          <FormItem prop="code" label="登录名">
            <Input ref="code" type="text" maxlength="20" v-model="form.code" placeholder="请输入登录名"></Input>
          </FormItem>
          <FormItem prop="password" label="用户密码">
            <Input ref="password" type="text" maxlength="20" v-model="form.pswd" placeholder=""></Input>
          </FormItem>
          <FormItem prop="needExpire" label="过期控制">
            <Checkbox v-model="form.needExpire">是否需要过期控制</Checkbox>
          </FormItem>
          <FormItem v-show="form.needExpire" prop="expire" label="过期时间">
            <DatePicker ref="expire" type="date" v-model="form.expireDate" placeholder="请选择过期时间" style="width: 100%"></DatePicker>
          </FormItem>
          <FormItem v-show="needUsbKey && form.id>0" prop="expire" label="" :label-width="0">
            <Button type="success" long @click="bindUsbKey">绑定U盾</Button>
          </FormItem> -->
        </Form>
      </Card>
      <Card class="cust-fields" style="margin: 0 5px">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          操作权限
        </p>
        <vxe-table empty-text="无可选角色" border resizable show-overflow show-header-overflow keep-source
          ref="chkTable" :data="roles" :height="500" :row-config="{isHover: true}" :checkbox-config="{checkField: 'check',}" >
          <vxe-column width="50" type="checkbox" field="check" fixed="left"></vxe-column>
          <vxe-column field="name" title="名称" header-align="center"></vxe-column>
        </vxe-table>
      </Card>
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          数据权限
        </p>
        <div style="height: 500px;overflow-x: hidden;overflow-y: auto;border: solid 0 red">
          <Tree ref="groupTree" :data="groups" show-checkbox check-strictly check-directly @on-check-change="noceCheckChanged"></Tree>
        </div>
      </Card>
      <!-- <ModalUsbKeyCheck v-model="showUsbKeyCheckModal" :item="editItem" /> -->
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">{{isAdd ? '创建用户' : '邀请用户'}}</Button>
    </div>
  </Modal>
</template>
<script>
import {mapState} from 'vuex'
// import ModalUsbKeyCheck from './ModalUsbKeyCheck'
export default {
  name: 'ModalUserEdit',
  components:{
    // ModalUsbKeyCheck
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // item: {
    //   type: Object,
    //   default(){ return {};}
    // },
  },
  data () {
    return {
      showModal: this.value,
      showUsbKeyCheckModal: false,
      loading: false,
      isAdd: false,
      custName: '',
      index: -1,
      editItem: {},
      allGroups: [],
      groups: [],
      roles: [],
      form: {
        name: '',
        mobile: '',
        pswd: '',
        roles: [],
        groups: [],
      },
      rules: {
        code: { required: true, type: 'string', max: 20, message: '登录名不为空且长度不超过20', trigger: 'blur' },
        name: { required: true, type: 'string', max: 20, message: '姓名不为空且长度不超过20', trigger: 'blur' },
        mobile: { required: true, type: 'string', max: 20, message: '手机号不为空且长度不超过20', trigger: 'blur' },
      },
      needUsbKey: false,
    }
  },
  computed: {
    ...mapState('auth', ['organs', 'customers']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.isAdd = false;
        this.form.name = '';
        this.form.password = '';
        this.form.mobile = '';
        this.getUserRoles();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  methods: {
    mobileChanged: function(){
      // console.log('mobile changed', this.form.mobile)
      let mobile = this.form.mobile.trim();
      if(mobile.length != 11)return;
      this.$axios.post(`sys/auth/CheckUserExistByMobile`, {mobile: this.form.mobile}).then(res => {
        if(res.code == 0){
          this.isAdd = !res.data.exist;
          this.form.name = res.data.name;
        }
      });
    },
    noceCheckChanged: function(nodes, node){
      // console.log('node check change', node, nodes)
      if(!node.checked)return;
      this.unchecksubs(node);
      for(let n of nodes){
        let subIds = this.getSubNodeIds(n);
        // console.log('get sub node ids', n.id, n.title, subIds);
        if(subIds.includes(node.id)){
          this.$set(n, 'checked', false);
        }
      }
    },
    getSubNodeIds: function(node){
      let ids = [];
      for(let n of node.children){
        ids.push(n.id);
        ids.push(...this.getSubNodeIds(n));
      }
      return ids;
    },
    unchecksubs: function(node){
      if(!node.children)return;
      for(let n of node.children){
        // n.checked = false;
        this.$set(n, 'checked', false);
        this.unchecksubs(n);
      }
    },
    getUserRoles: function(){
      this.$axios.post(`sys/auth/QueryUserAgentsOld`, {id: this.form.id}).then(res => {
        this.$set(this, 'groups', res.data.groups);
        this.$set(this, 'roles', res.data.roles);
      })
    },
    ok: async function () {
      let mobile = this.form.mobile.trim();
      if(mobile.length != 11){
        this.$Message.warning('请输入手机号');
        return;
      }
      if(this.isAdd){
        if(this.form.name.trim().length == 0){
          this.$Message.warning('请输入姓名');
          return;
        }
        if(this.form.pswd.trim().length == 0){
          this.$Message.warning('请输入密码');
          return;
        }
      }
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      let userGroups = [];
      for(let cn of chkNodes){
        // console.log('get check group', cn.id, cn.title)
        userGroups.push(cn.id);
      }
      let userRoles = [];
      for(let role of this.roles){
        if(role.check){
        // console.log('get check role', role.id, role.name)
          userRoles.push(role.id);
        }
      }
      if(userRoles.length == 0){
        this.$Message.warning('必须选择操作权限');
        return;
      }
      if(userGroups.length == 0){
        this.$Message.warning('必须选择数据权限');
        return;
      }
      this.form.roles = userRoles;
      this.form.groups = userGroups;
      // this.form.expireDate = new Date(this.form.expireDate).format('yyyy-MM-dd HH:mm:ss');
      this.loading = true;
      let url = 'sys/auth/InviteUserOld';
      if(this.isAdd){
        url = 'sys/auth/CreateUserOld';
      }
      let res = await this.$axios.post(url, this.form);
      this.loading = false;
      if(res.code !== 0)return;
      this.$Message.info('保存成功');
      setTimeout(() => {
        this.showModal = false;
        this.$emit('saved');
      }, 800);
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.user-edit-content{
  display: flex;
}
.cust-fields{
  width: 300px;
  flex: auto;
}
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>