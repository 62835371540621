<template>
<div class="edit-container">
  <div class="edit-area">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" height="auto" :row-config="{isHover: true}">
      <vxe-column type="seq" width="60" ></vxe-column>
      <vxe-column field="name" :title="showLang('com.tab.title')" width="300" header-align="center"></vxe-column>
      <vxe-column field="remark" title="角色说明" header-align="center"></vxe-column>
      <vxe-column width="220" title="操作" fixed="right">
        <template #header>
          <Button v-if='funCodes("9501")' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
          <Button v-if='funCodes("9500")' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if='funCodes("9502")' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
          <Button v-if='funCodes("9503")' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <ModalRoleEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalRoleEdit from './ModalRoleEdit'
export default {
  name: 'ModalRoleList',
  components:{
    ModalRoleEdit,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      editItem: {},
      showEditModal: false,
      loading: false,
      isAdd: false,
      list: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
  },
  mounted: function(){
    this.getList();
  },
  methods: {
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}, cust: this.item};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row, cust: this.item};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除角色[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`sys/auth/DeleteRoleOld`, {id: params.row.id}).then(res => {
            if(res.code != 0)return;
            this.getList();
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`sys/auth/QueryRolesOld`, {}).then(res => {
        if(res.code != 0)return;
        this.$set(this, 'list', res.data);
        this.$refs.chkTable.reloadData(res.data)
      });
    },
    itemSaved: function(){
      this.getList();
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.edit-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area{
  /* border: solid 1px blue; */
  width: 100%;
  height: 600px;
  flex: auto;
  /* display: flex; */
}
.form-area{
  width: 410px;
  flex: none;
}
.fun-area{
  width: 400px;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all{
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>